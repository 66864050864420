<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  methods: {
    createTitleDesc: function (routeInstance) {
      //titleを設定する処理
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title;
        document.title = setTitle;
      } else {
        document.title =
          "ルートでtitleがセットされていない場合に表示するテキスト";
      }
    },
  },
};
</script>