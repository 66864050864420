import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    email: '',
    password: '',
    loginEmail: '',
    loginPassword: '',
    authenticatedUser: '',
    clients: [],
    selectedClient: "",
    user: "",
    projectList: [],
    allProject: [],
    managerList: [],
    clientIdList: [],
  },
  mutations: {
    changeDBAndNumber(state, clientDBList) {
      var clientList = state.clients;
      console.log(clientList);
      console.log(clientDBList);
      for (var j = 0; j < clientList.length; j++) {
        for (var t = 0; t < clientList.length; t++) {
          if (clientList[j].client_id == clientDBList[t].clientId) {
            console.log(clientList[j].client_id);
            Vue.set(state.clients[j], 'used_db', clientDBList[t].used_db);
            Vue.set(state.clients[j], 'use_number', clientDBList[t].used_number);
          }
          if (clientList[j].client_id == clientDBList[t].client_id) {
            console.log(clientList[j].client_id);
            Vue.set(state.clients[j], 'used_db', clientDBList[t].used_db);
            Vue.set(state.clients[j], 'use_number', clientDBList[t].used_number);
          }
        }
      }
    },
    setClientInfo(state, clientInfo) {//firebase storageから取得したクライアント情報全てをセットする
      state.clients.push(clientInfo);
    },
    selectedClientSetter(state, selectedIndex) {//選択されているクライアントのインデントをセットする
      state.selectedClient = selectedIndex;
    },
    setUser(state, value) {
      state.user = value;
    },
    ///////////////////////////////////////
    ///////説明
    //////クライアントのIDリストを格納する
    //
    //
    //引数：state:ステート clientId:クライアントのIDリスト
    //////////////////////////////////////////////////////////////////////
    setClientIdList(state, clientId) {
      for (var i = 0; i < clientId.length; i++) {
        state.clientIdList.push(clientId[i]);
      }
    },
    ///////////////////////////////////////
    ///////説明
    //////firebase storageから取得したユーザーリストをセットする
    //
    //
    //引数：state:ステート userList:あるプロジェクトに属するユーザーリスト
    //////////////////////////////////////////////////////////////////////
    setProjectInfo(state, userList) {
      state.projectList.push(userList);
    },
    ///////////////////////////////////////
    ///////説明
    //////クライアントを削除するためにそのクライアントに属するプロジェクト情報
    //    すべてを削除する
    //
    //引数：state:ステート allProject:すべてのプロジェクト
    //////////////////////////////////////////////////////////////////////
    setAllProject(state, allProject) {
      state.allProject = [];
      state.allProject.push(allProject);
    },
    ///////////////////////////////////////
    ///////説明
    //////クライアント一覧を更新順に並び替える
    //
    //
    //引数：state:ステート
    //////////////////////////////////////////////////////////////////////
    changeClientInfo(state) {
      state.clients.sort(function (a, b) {
        return (a.update_date > b.update_date ? 1 : -1);
      });
    },
    ///////////////////////////////////////
    ///////説明
    //////firebase storageから取得した管理者リストをセットする
    //
    //
    //引数：state:ステート managerList:管理者リスト
    //////////////////////////////////////////////////////////////////////
    setManagerInfo(state, managerList) {
      state.managerList.push(managerList);

    },
    resetProject(state) {
      state.projectList = [];
    },
    resetManager(state) {
      state.managerList = [];
    },
    resetUser(state) {
      state.user = "";
      state.loginEmail = "";
      state.loginPassword = "";
      state.clients = [];
      state.clientIdList = [];
    },
    resetClientInfo(state) {//クライアント管理画面へ遷移した際に値を一度リセットする
      state.clients = [];
      state.clientIdList = [];
    },
    ///////////////////////////////////////
    ///////説明
    //////クライアントを削除した際にそのクライアントをデータから消す
    //
    //
    //引数：state:ステート
    //////////////////////////////////////////////////////////////////////
    deleteClient(state) {
      state.clients.splice(state.selectedClient, 1);
    }
  },
  plugins: [createPersistedState()] // この行でvuexに「vuex-persistedstate」を追加
})
