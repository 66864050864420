<template>
  <div class="home">
    <Login @child-emit="loginUser" />
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-auth.js"></script>
<script type="module">
// @ is an alias to /src
import Login from "@/components/Login/Login.vue";
import { VueLoading } from "vue-loading-template";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const app = firebase.initializeApp(firebaseConfig);

export default {
  name: "LoginPage",
  components: {
    Login,
    VueLoading,
  },
  methods: {
    checkvalidate() {
      if (
        //メール及びパスが共に未入力
        this.$store.state.loginEmail == "" &&
        this.$store.state.loginPassword == ""
      ) {
        document.getElementById("auth-alert-text").style.display = "none";
        document.getElementById("pass-alert").style.display = "none";
        document.getElementById("mail-alert").style.display = "none";
        document.getElementById("pass-mail-alert").style.display = "block";
        return false;
      } else if (
        //メールアドレスのみ未入力
        this.$store.state.loginEmail == "" &&
        this.$store.state.loginPassword != ""
      ) {
        document.getElementById("auth-alert-text").style.display = "none";
        document.getElementById("pass-mail-alert").style.display = "none";
        document.getElementById("pass-alert").style.display = "none";
        document.getElementById("mail-alert").style.display = "block";
        return false;
      } else if (
        this.$store.state.loginEmail != "" &&
        this.$store.state.loginPassword == ""
      ) {
        document.getElementById("auth-alert-text").style.display = "none";
        document.getElementById("mail-alert").style.display = "none";
        document.getElementById("pass-mail-alert").style.display = "none";
        document.getElementById("pass-alert").style.display = "block";
        return false;
      } else {
        document.getElementById("auth-alert-text").style.display = "none";
        document.getElementById("mail-alert").style.display = "none";
        document.getElementById("pass-mail-alert").style.display = "none";
        document.getElementById("pass-alert").style.display = "none";
        return true;
      }
    },
    createSession(user) {
      console.log("---createSession---");
      user.user.getIdToken().then((idToken) => {
        console.log(idToken);
        const csrfToken = this.getCookie("csrfToken");
        console.log(csrfToken);

        const body = encodeURIComponent(idToken);

        // const json_text = { token: idToken, csrf: csrfToken };
        // var body = JSON.stringify(json_text);
        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open(
          "POST",
          "https://us-central1-admin-short-video.cloudfunctions.net/sessionCreate"
        );
        // xmlHttpRequest.setRequestHeader("Content-Type", "application/json");
        xmlHttpRequest.onload = function (event) {
          console.log("処理成功");
          console.log(event);
          console.log(document.cookie);
        };
        xmlHttpRequest.send(body);
      });
    },
    getCookie(csrfToken) {
      var v = document.cookie.match("(^|;) ?" + csrfToken + "=([^;]*)(;|$)");
      return v ? v[2] : null;
    },
    loginUser() {
      var store = this.$store;
      var instance = this;
      if (this.checkvalidate() == true) {
        //ローディング表示
        document.getElementById("loading").style.display = "block";
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            firebase
              .auth()
              .signInWithEmailAndPassword(
                this.$store.state.loginEmail,
                this.$store.state.loginPassword
              )
              .then(
                (user) => {
                  firebase
                    .storage()
                    .ref()
                    .child("/admin/admin.json")
                    .getDownloadURL()
                    .then((url) => {
                      var xhr = new XMLHttpRequest();
                      xhr.open("GET", url);
                      xhr.onload = function (event) {
                        var blob = xhr.response;
                        var adminInfo = JSON.parse(blob);
                        if (adminInfo.uid == user.user.uid) {
                          store.commit("setUser", "true");
                          // 成功時の処理
                          document.getElementsByClassName(
                            "auth-alert-text"
                          )[0].style.display = "none";
                          //ローディング非表示
                          document.getElementById("loading").style.display =
                            "none";
                          instance.$router.push("/clientManager");
                        } else {
                          //ローディング非表示
                          document.getElementById("loading").style.display =
                            "none";
                          document.getElementsByClassName(
                            "auth-alert-text"
                          )[0].style.display = "block";
                        }
                      };
                      xhr.send();
                    })
                    .catch((error) => {
                      document.getElementById("loading").style.display = "none";
                      document.getElementsByClassName(
                        "auth-alert-text"
                      )[0].style.display = "block";
                    })
                    .then((response) => {
                      // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
                      const data = response;
                    });
                },
                (error) => {
                  // Handle Errors here.
                  var errorCode = error.code;
                  var errorMessage = error.message;
                  //ローディング非表示
                  document.getElementById("loading").style.display = "none";
                  document.getElementsByClassName(
                    "auth-alert-text"
                  )[0].style.display = "block";
                }
              );
          });
      }
    },
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authenticatedUser = true;
      } else {
        this.authenticatedUser = false;
      }
    });
  },
};
</script>