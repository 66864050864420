<template>
  <div class="login-page">
    <div class="form">
      <form class="login-form" @submit.prevent="emitEvent">
        <p class="form-name">管理画面（Client）</p>
        <input
          class="form-group"
          type="text"
          v-model="id"
          placeholder="メールアドレス"
        />
        <input
          class="form-group"
          type="password"
          v-model="password"
          placeholder="パスワード"
        />
        <button class="login-text" type="submit">ログイン</button>
      </form>
      <div class="loading">
        <vue-loading
          id="loading"
          type="spin"
          color="#333"
          :size="{ width: '30px', height: '30px' }"
          style="margin-top:10px"
        ></vue-loading>
      </div>
      <div class="auth-alert">
        <p id="auth-alert-text" class="auth-alert-text">
          ※認証情報が正しくありません
        </p>
        <p id="pass-alert">パスワードを入力してください</p>
        <p id="mail-alert">メールアドレスを入力してください</p>
        <p id="pass-mail-alert">
          メールアドレス及びパスワードを入力してください
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { VueLoading } from "vue-loading-template";
export default {
  name: "Login",
  components: {
    VueLoading,
  },
  mounted() {
    document.body.id = "login-body";
    document.getElementById("login-body").style.background =
      "linear-gradient(45deg, #f0ddd0, 60%, #e6e6fa)";
    document.querySelector("html").style.height = "100%";
    this.$root.headerHide = true;
    this.$root.footerHide = true;
    document.getElementById("loading").style.display="none";
  },
  computed: {
    id: {
      get() {
        return this.$store.state.loginEmail;
      },
      set(value) {
        this.$store.state.loginEmail = value;
      },
    },
    password: {
      get() {
        return this.$store.state.loginPassword;
      },
      set(value) {
        this.$store.state.loginPassword = value;
      },
    },
  },
  methods: {
    emitEvent() {
      this.$emit("child-emit");
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap");

#login-body {
  background: linear-gradient(45deg, #f0ddd0, 60%, #e6e6fa);
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.login-page {
  width: 100%;
}
.form-name {
  margin-top: 3%;
  font-size: 24px;
  color: #43444a;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
}

.login-text {
  font-family: "Noto Sans JP", sans-serif;
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
  background: #ffffff;
  max-width: 380px;
  padding: 30px 45px 10px 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}
.form-group {
  font-family: "Noto Sans JP", sans-serif;
  outline: 0;
  width: 70%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 10px;
  height: 45px;
  border: 2px solid #f2f2f2;
}
.form button {
  margin-top: 3%;
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(#ff8c00, 25%, #ff5f17);
  width: 70%;
  height: 45px;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  border-radius: 10px;
}

.auth-alert {
  margin-top: 5%;
  margin-left: 15%;
}
.auth-alert-text {
  text-align: left;
  font-size: 10px;
  color: red;
  display: none;
}

#mail-alert {
  text-align: left;
  font-size: 10px;
  color: red;
  display: none;
}

#pass-alert {
  text-align: left;
  font-size: 10px;
  color: red;
  display: none;
}

#pass-mail-alert {
  text-align: left;
  font-size: 10px;
  color: red;
  display: none;
}
</style>

