import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import "firebase/auth"
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: Login,
  },
  {
    path: '/clientManager',
    name: 'Client',
    component: () => import('../views/Client/ClientListHome.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/clientRewrite',
    name: 'ClientRewrite',
    component: () => import('../views/Client/ClientRewrite.vue'),
    props: true,
    meta: { requiresAuth: true, }
  },
  {
    path: '/clientRegister',
    name: 'ClientRegister',
    component: () => import('../views/Client/RegisterClientForm.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
/*
router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const auth = getAuth();
  const user = auth.currentUser;
  if (requiresAuth) {
    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    if (!user) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // next() を常に呼び出すようにしてください!
  }
})
*/

router.beforeEach((to, from, next) => {
  console.log("routerがはしりました")
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // let currentUser = firebase.auth().currentUser
  if (requiresAuth) {
    console.log("routerのif文に入りました");

    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    if (store.state.user == "") {
      console.log("ログインされていません");

      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      console.log("ログインされています");
      next()
    }
  } else {
    console.log("ここのif文が走りました")
    console.log(requiresAuth);
    next() // next() を常に呼び出すようにしてください!
  }
})

export default router
